
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        















































































.activity,
[class*='activity--'] {
  overflow: hidden;
}

.activity__hr {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 30px, xxl: 80px));
}

.activity__gallery {
  // prettier-ignore
  @include fluid(margin-bottom,(xxs: 25px, xxl: 160px));
}

.activity__footer {
  // prettier-ignore
  @include fluid(padding-top,(xxs: 45px, xxl: 160px));

  background: $c-skin-light;
}
